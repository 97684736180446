import { apiSrv ,adminDomain} from "../constants/defaultValues";


const urlfy = obj => Object
    .keys(obj)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]))
    .join('&');


export const get = async (method, params) => {
    let uriparams = params ? "?" + urlfy(params) : "";
    console.log(`${apiSrv}${method}${uriparams}`);
    const query = await fetch(`${apiSrv}${method}${uriparams}`,
        {
            method: "GET",
            //mode: "cors",
            mode: "same-origin", 
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrer: "no-referrer"
        });
    let response = await query.json();
    return response;
}

export const post = async (method) => {
    console.log(`${apiSrv}${method}`);
    const query = await fetch(`${apiSrv}${method}`,
        {
            method: "POST",
            //mode: "cors",
            mode: "same-origin",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrer: "no-referrer"
        });
    let response = await query.json();
    return response;
}

export const getPrint = async (method, params) => {
    let uriparams = params ? "?" + urlfy(params) : "";
    const query = await fetch(`${adminDomain}/rest_login/imprime${uriparams}`,
        {
            method: "GET",
            //mode: "cors",
            mode: "same-origin",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrer: "no-referrer"
        });
    let response = await query.json();
    return response;
}

export const getTable = async (method, params) => {
    let uriparams = params ? "?" + urlfy(params) : "";
    console.log(`${adminDomain}${method}${uriparams}`);
    const query = await fetch(`${adminDomain}${method}${uriparams}`,
        {
            method: "GET",
            //mode: "cors",
            mode: "same-origin",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrer: "no-referrer"
        });
    let response = await query.json();
    return response;
}
